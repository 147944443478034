import config from '../config';
import DashboardLayout from '../layouts/DashboardLayout';
import LoginLayout from '../layouts/LoginLayout';
import {
  Home,
  DashboardLogin,
  Dashboard,
  DashboardNotifiSim,
  DashboardAdmin,
  DashboardSimData,
  DashboardCateGoiCuoc,
} from '../pages';

//component pages

const publicRoutes = [
  { path: config.routes.home, component: Home },
  { path: config.routes.homeInternet, component: Home },
  { path: config.routes.dashboardLogin, component: DashboardLogin, layout: LoginLayout },
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardDataSim, component: DashboardSimData, layout: DashboardLayout },
  { path: config.routes.dashboardCateSim, component: DashboardCateGoiCuoc, layout: DashboardLayout },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
