import * as requestAPI from '../utils/requestAPI';

//API THÊM USER
export const addCateSim = async (data) => {
  try {
    const res = await requestAPI.postSim(`/addcategc`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listCateSimIDAll = async (user) => {
  try {
    const res = await requestAPI.getSim(`/listcategcall?u=${user}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getCateSimID = async (id) => {
  try {
    const res = await requestAPI.getSim(`/getcategcid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCateSimID = async (data) => {
  try {
    const res = await requestAPI.postSim(`/updatecategc`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleCateSimID = async (data) => {
  try {
    const res = await requestAPI.postSim(`/deleidcategc`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
