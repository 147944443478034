import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import classNames from 'classnames/bind';
import { checkTokenDashboad, getLocalStorage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import {
  addCateSim,
  deleCateSimID,
  getCateSimID,
  listCateSimIDAll,
  updateCateSimID,
} from '../../../services/apoCateSim';

function CateSim() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      borderBottom: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  const datenow = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

  const [resultCateSim, setResultCateSim] = useState([]);
  const [resultCateSimID, setResultCateSimID] = useState({
    title: '',
    id_cate_pater: '1',
  });
  const navigate = useNavigate();

  const fetchApiCateSim = async () => {
    const resultAPISIM = await listCateSimIDAll(getLocalStorage('token')['id']);
    if (resultAPISIM['data'] && resultAPISIM['data'].length > 0) {
      setResultCateSim(resultAPISIM['data']);
    }
  };
  const handleGetCateSimID = async (id) => {
    const resultAPISIM = await getCateSimID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultCateSimID(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    fetchApiCateSim();
  }, []);

  const handleChangValueCateSim = (jsonVL) => {
    setResultCateSimID({ ...resultCateSimID, ...jsonVL, update_date: datenow });
  };

  const handelUpdateCateSim = async () => {
    const resultAPISIM = await updateCateSimID(resultCateSimID);
    if (resultAPISIM['data'].length > 0) {
      setResultCateSimID({
        title: '',
        id_cate_pater: '1',
      });
      setResultCateSim(resultAPISIM['data']);
    }
  };
  const handelAddCateSim = async () => {
    const data = {
      ...resultCateSimID,
      id_user: getLocalStorage('token')['id'],
      status: 1,
      create_date: datenow,
    };
    const resultAPISIM = await addCateSim(data);
    if (resultAPISIM['data'].length > 0) {
      setResultCateSimID({
        title: '',
        id_cate_pater: '1',
      });
      setResultCateSim(resultAPISIM['data']);
    }
  };

  const handleChangValueAdmin = (jsonVL) => {
    setResultCateSimID({ ...resultCateSimID, ...jsonVL });
  };

  const deleIDDataSim = async (data) => {
    const resultAPISIM = await deleCateSimID(data);
    setResultCateSim(resultAPISIM['data']);
  };
  return (
    <Container sx={{ pb: '75px' }}>
      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <Stack direction={'column'} spacing={2}>
            <Select
              value={resultCateSimID['id_cate_pater']}
              label="Thể Loại Cha"
              onChange={(e) => {
                handleChangValueAdmin({ id_cate_pater: e.target.value });
              }}
            >
              <MenuItem value={'1'}>Di động</MenuItem>
              <MenuItem value={'2'}>Cố Định</MenuItem>
            </Select>
            <TextField
              label="Tên Thể Loại Con"
              size="small"
              placeholder="Nhập tên thể loại con"
              value={resultCateSimID?.title}
              onChange={(e) => {
                handleChangValueCateSim({ title: e.target.value });
              }}
              focused
              required
            />
            <Button
              variant="contained"
              color="error"
              onClick={resultCateSimID?.id ? handelUpdateCateSim : handelAddCateSim}
            >
              {resultCateSimID?.id ? 'Cập Nhật' : 'Lưu Lại'}
            </Button>
          </Stack>
        </Grid>
        <Grid item md={7} xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Thể Loại Cha</StyledTableCell>
                  <StyledTableCell align="center">Thể Loại Con</StyledTableCell>
                  <StyledTableCell align="center">Tác Vụ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultCateSim?.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="center">{row.id_cate_pater === 1 ? 'Di động' : 'Cố Định'}</StyledTableCell>
                    <StyledTableCell align="center">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => handleGetCateSimID(row.id)}
                        sx={{ color: resultCateSimID.id === row.id ? `red` : `` }}
                      >
                        Sửa
                      </Button>
                      <Button onClick={() => deleIDDataSim({ id: row.id, id_user: row.id_user })}>Xóa</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CateSim;
