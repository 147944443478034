import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import LtePlusMobiledataIcon from '@mui/icons-material/LtePlusMobiledata';
import RouterIcon from '@mui/icons-material/Router';

import { ThemeContext } from '../../component/ThemeContext/ThemeContext';
import { ButtonUI, FrameInfoSim } from '../../component/CustomerMUI';
import styles from './Home.module.scss';
import images from '../../assets/images';
import { Box } from '@mui/system';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { listUserID } from '../../services/apiUsers';
import { numberFormat, numberFormatText } from '../../utils/helper';
import FrameInfoSim1 from '../../component/CustomerMUI/FrameInfoSim/FrameInfoSim1';
import { API_URL_SIMSO } from '../../config/config';
import { listIDCatePater, listSimDataCate, listSimDataCateChild } from '../../services/apiSimData';

const cx = classNames.bind(styles);

function Home() {
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const [authDomain, setAuthDomain] = useState({});
  const [resultGoiCuoc, setResultGoiCuoc] = useState({});
  const [idCateChild, setIdCateChild] = useState();
  const [resultCateChild, setResultCateChildGC] = useState([]);
  const [resultInternet, setResultInternet] = useState({});
  const [resultSimDetail, setResultSimDetail] = useState({});
  const [open, setOpen] = useState(false);
  const contextApiUser = useContext(ThemeContext);
  const { idUrl } = useParams();

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
      document.title = resultAPISIM[0]['TITLE_HOME'];
    }
  };
  const fetchApiDataSimCateChild = async (idcate) => {
    setIdCateChild(0);
    const resultAPISIM = await listIDCatePater(idcate);
    if (resultAPISIM.length > 0) {
      setResultCateChildGC(resultAPISIM);
    }
  };
  // const fetchApiDataSimAll = async () => {
  //   const resultAPIGoiCuoc = await listSimDataCate(1);
  //   if (resultAPIGoiCuoc.length > 0) {
  //     const duplicateItems = {};
  //     // Iterate over the data array and group items by id_cate
  //     resultAPIGoiCuoc.forEach((item) => {
  //       if (!duplicateItems[item.catechild]) {
  //         duplicateItems[item.catechild] = [];
  //       }
  //       duplicateItems[item.catechild].push(item);
  //     });
  //     setResultGoiCuoc(duplicateItems);
  //   }

  //   const resultAPIInternet = await listSimDataCate(2);
  //   if (resultAPIInternet.length > 0) {
  //     const duplicateinterItems = {};
  //     // Iterate over the data array and group items by id_cate
  //     resultAPIInternet.forEach((item) => {
  //       if (!duplicateinterItems[item.catechild]) {
  //         duplicateinterItems[item.catechild] = [];
  //       }
  //       duplicateinterItems[item.catechild].push(item);
  //     });
  //     setResultInternet(duplicateinterItems);
  //   }
  // };
  const fetchApiDataCateChild = async (cate, cate_child) => {
    setIdCateChild(cate_child);
    if (cate === 1) {
      const resultAPIGoiCuoc = await listSimDataCateChild(cate, cate_child);
      if (resultAPIGoiCuoc.length > 0) {
        const duplicateItems = {};
        // Iterate over the data array and group items by id_cate
        resultAPIGoiCuoc.forEach((item) => {
          if (!duplicateItems[item.catechild]) {
            duplicateItems[item.catechild] = [];
          }
          duplicateItems[item.catechild].push(item);
        });
        setResultGoiCuoc(duplicateItems);
      }
    } else {
      const resultAPIInternet = await listSimDataCateChild(cate, cate_child);
      if (resultAPIInternet.length > 0) {
        const duplicateinterItems = {};
        // Iterate over the data array and group items by id_cate
        resultAPIInternet.forEach((item) => {
          if (!duplicateinterItems[item.catechild]) {
            duplicateinterItems[item.catechild] = [];
          }
          duplicateinterItems[item.catechild].push(item);
        });
        setResultInternet(duplicateinterItems);
      }
    }
  };
  const fetchApiDataAll = async (cate) => {
    if (cate === 1) {
      const resultAPIGoiCuoc = await listSimDataCate(cate);
      if (resultAPIGoiCuoc.length > 0) {
        const duplicateItems = {};
        // Iterate over the data array and group items by id_cate
        resultAPIGoiCuoc.forEach((item) => {
          if (!duplicateItems[item.catechild]) {
            duplicateItems[item.catechild] = [];
          }
          duplicateItems[item.catechild].push(item);
        });
        setResultGoiCuoc(duplicateItems);
      }
    } else {
      const resultAPIInternet = await listSimDataCate(cate);
      if (resultAPIInternet.length > 0) {
        const duplicateinterItems = {};
        // Iterate over the data array and group items by id_cate
        resultAPIInternet.forEach((item) => {
          if (!duplicateinterItems[item.catechild]) {
            duplicateinterItems[item.catechild] = [];
          }
          duplicateinterItems[item.catechild].push(item);
        });
        setResultInternet(duplicateinterItems);
      }
    }
  };

  useEffect(() => {
    fetchApiUserID();
    if (idUrl !== 'internet') {
      fetchApiDataSimCateChild(1);
      fetchApiDataAll(1);
    } else {
      fetchApiDataSimCateChild(2);
      fetchApiDataAll(2);
    }
  }, [idUrl]);

  const handleDetailSim = (price, tomtat, title = '') => {
    return [price && `<b>${numberFormat(price)}<b/>`, tomtat && `${title && title + ':'}${tomtat}`];
  };
  const handleDetailSimInternet = (tocdo, tomtat, price) => {
    return [price && `<b>${numberFormat(tocdo)}<b/>`, tomtat && `${tomtat}`, price && `<b>${numberFormat(price)}<b/>`];
  };

  const handleOnSubmitCate = (url) => {
    navigate(url, { replace: true });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container sx={{ p: 0, pb: '75px' }}>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <div className={cx('main-content-cate')}>
          <Grid item md={12} xs={12}>
            <img
              src={`${API_URL_SIMSO}/getimage?name=bannersimdata.jpg`}
              className={cx('img_banner')}
              alt="qc"
              width={'100%'}
            />
          </Grid>
        </div>
        <div className={cx('main-content-cate', 'bg-red')}>
          <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
            <Grid
              item
              xs={6}
              md={6}
              onClick={() => {
                handleOnSubmitCate('/');
                fetchApiDataSimCateChild(1);
                fetchApiDataAll(1);
              }}
            >
              <Paper elevation={0} sx={{ borderRadius: 2 }}>
                <Card variant="outlined" sx={{ borderRadius: 2 }}>
                  <CardContent
                    sx={{ p: 1, '&:last-child': { pb: 1 } }}
                    className={cx(idUrl === undefined ? 'active-cate-ns' : '')}
                  >
                    {' '}
                    <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                      <h5>DỊCH VỤ DI ĐỘNG</h5>
                      <LtePlusMobiledataIcon color={idUrl === undefined ? 'error' : 'action'} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                      <span>Những gói cước, data khủng cho di động...</span>
                    </Stack>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              onClick={() => {
                handleOnSubmitCate('/internet');
                fetchApiDataSimCateChild(2);
                fetchApiDataAll(2);
              }}
            >
              <Paper elevation={0} sx={{ borderRadius: 2 }}>
                <Card variant="outlined" sx={{ borderRadius: 2 }}>
                  <CardContent
                    sx={{ p: 1, '&:last-child': { pb: 1 } }}
                    className={cx(idUrl === 'internet' ? 'active-cate-ns' : '')}
                  >
                    {' '}
                    <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                      <h5>WIFI - TRUYỀN HÌNH</h5>
                      <RouterIcon color={idUrl === 'internet' ? 'error' : 'action'} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                      <span>Những gói Internet, Truyền Hình cực khủng...</span>
                    </Stack>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1 }}>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <DialogTitle className={cx('UTM')}>
              <b>CHI TIẾT GÓI</b>
            </DialogTitle>
            <Button onClick={handleClose}>ĐÓNG LẠI</Button>
          </Stack>
          <DialogContent sx={{ mt: -4 }}>
            {idUrl !== 'internet' && (
              <DialogContentText>
                <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
                  <Grid item xs={4}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Tên gói</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{resultSimDetail.title}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Giá gói</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{numberFormat(resultSimDetail.price)}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Thời gian</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{resultSimDetail.dategoi}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={cx('pack-detail__box')}>
                      <div className={cx('pack-detail__box-inner')}>
                        <div className={cx('pack-detail__box-top')}>
                          <h5 className={cx('pack-detail__box-sub')}>Ưu đãi</h5>
                        </div>{' '}
                        <div className={cx('pack-detail__box-content')}>
                          <div
                            className={cx('pack-detail__box-info')}
                            style={{ minHeight: '230px', maxHeight: '230px' }}
                          >
                            <p className={cx('pack-detail__box-des')}>
                              {resultSimDetail.content?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContentText>
            )}
            {idUrl === 'internet' && (
              <DialogContentText>
                <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
                  <Grid item md={3} xs={6}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Tên gói</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{resultSimDetail.title}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Giá gói</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{numberFormat(resultSimDetail.price)}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Tốc độ</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{resultSimDetail.tocdogoi}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          <h5 className={cx('UTM')}>Thời gian</h5>
                          <Stack direction="column" spacing={0}>
                            <h4 className={cx('FS')}>{resultSimDetail.dategoi}</h4>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={cx('pack-detail__box')}>
                      <div className={cx('pack-detail__box-inner')}>
                        <div className={cx('pack-detail__box-top')}>
                          <h5 className={cx('pack-detail__box-sub')}>Ưu đãi</h5>
                        </div>{' '}
                        <div className={cx('pack-detail__box-content')}>
                          <div
                            className={cx('pack-detail__box-info')}
                            style={{ minHeight: '230px', maxHeight: '230px' }}
                          >
                            <p className={cx('pack-detail__box-des')}>
                              {resultSimDetail.content?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {idUrl === 'internet' && (
              <ButtonUI
                bgrColorBtn="#fd3a51"
                colorText="#fff"
                borderBtn="#fd3a51"
                borderRadius={10}
                href={`tel:${resultSimDetail.phone_call}`}
                fullWidth
              >
                GỌI NGAY
              </ButtonUI>
            )}
            {idUrl !== 'internet' && (
              <ButtonUI
                bgrColorBtn="#fd3a51"
                colorText="#fff"
                borderBtn="#fd3a51"
                borderRadius={10}
                href={`sms:9123?&body=${resultSimDetail.phone_sms}`}
                fullWidth
              >
                ĐĂNG KÝ
              </ButtonUI>
            )}
          </DialogActions>
        </Dialog>
        <div className={cx('main-filter-content', 'bg-red')}>
          <div className={cx('box-list-subcate')}>
            <div className={cx('list-subcate')}>
              <Stack direction="row" spacing={1}>
                {resultCateChild.map((cate) => (
                  <ButtonUI
                    key={cate.id}
                    bgrColorBtn={idCateChild === cate.id ? '#ffffff' : '#ffffff'}
                    colorText={idCateChild === cate.id ? '#ee0000' : '#444444'}
                    borderBtn={idCateChild === cate.id ? '#FFEB3B' : '#c0c0c0'}
                    onClick={() => fetchApiDataCateChild(cate.id_cate_pater, cate.id)}
                  >
                    {idCateChild === cate.id ? <b>{cate.title}</b> : cate.title}
                  </ButtonUI>
                ))}
              </Stack>
            </div>
          </div>
        </div>

        {idUrl !== 'internet' &&
          Object.values(resultGoiCuoc).map((items, i) => (
            <div className={cx('main-content')}>
              <Grid container key={i} sx={{ pb: 1 }}>
                <Grid item xs={12} className={cx('css-title')}>
                  <label>{items[0]['catechild']}</label>
                </Grid>
                {items.map((gg) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={gg.id}
                    onClick={() => {
                      setResultSimDetail(gg);
                    }}
                  >
                    <FrameInfoSim
                      bgrFrame={'red'}
                      title={gg.title}
                      sms={gg.phone_sms}
                      arrDetailSim={handleDetailSim(gg.price, gg.content, gg.title)}
                      onClick1={handleClickOpen}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}

        {idUrl === 'internet' &&
          Object.values(resultInternet).map((items, i) => (
            <div className={cx('main-content')}>
              <Grid container key={i} sx={{ pb: 1 }}>
                <Grid item xs={12} className={cx('css-title')}>
                  <label>{items[0]['catechild']}</label>
                </Grid>
                {items.map((iter) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={iter.id}
                    onClick={() => {
                      setResultSimDetail(iter);
                    }}
                  >
                    <FrameInfoSim1
                      bgrFrame={'red'}
                      title={iter.title}
                      phone={iter.phone_call}
                      arrDetailSim={handleDetailSimInternet(iter.tocdogoi, iter.content, iter.price)}
                      onClick1={handleClickOpen}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
      </Grid>
    </Container>
  );
}

export default Home;
