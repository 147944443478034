import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SimCardIcon from '@mui/icons-material/SimCard';
import LtePlusMobiledataIcon from '@mui/icons-material/LtePlusMobiledata';
import RouterIcon from '@mui/icons-material/Router';

import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import images from '../../../assets/images';
import { styled } from '@mui/material/styles';
import { AppBar, Grid, IconButton, Toolbar } from '@mui/material';
import classNames from 'classnames/bind';
import styles from '../Footer/Footer.module.scss';
import auth from '../../../config/auth';
import { Stack } from '@mui/system';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
const cx = classNames.bind(styles);

function Footer() {
  const hostname = window.location.hostname;
  const contextApiUser = useContext(ThemeContext);
  let authDomain = contextApiUser.dataUser;
  return (
    <Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }} elevation={3}>
        <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, backgroundColor: '##f9f9f9' }}>
          <Grid container justifyContent="center" sx={{ pt: '5px' }}>
            <Grid item md={6} xs={12}>
              <Toolbar sx={{ justifyContent: 'center', ml: 2 }}>
                <Stack direction="row" spacing={3} alignItems="flex-end">
                  {authDomain['HOTTLINE_ZALO'] && (
                    <Box>
                      <Stack direction="column" alignItems="center">
                        <Box className={cx('call-animation')} sx={{ mb: 1 }}>
                          <a href={`https://zalo.me/${authDomain['HOTTLINE_ZALO']}`} target="_bank">
                            <img src={images.iconZalo} alt={'zalo'} className={cx('img-m')} />
                          </a>
                        </Box>
                        <b className={cx('font-11')}>Zalo</b>
                      </Stack>
                    </Box>
                  )}
                  {authDomain['HOTTLINE_SIM_CALL'] && (
                    <Box>
                      <Stack direction="column" alignItems="center">
                        <Box className={cx('call-animation-red')} sx={{ mb: 1 }}>
                          <a href={`tel:${authDomain['HOTTLINE_SIM_CALL']}`}>
                            <Fab aria-label="phone" color="error" className={cx('img-m', 'h-w-45')}>
                              <PhoneInTalkRoundedIcon />
                            </Fab>
                          </a>
                        </Box>
                        <b className={cx('font-11')}>Gọi tư vấn</b>
                      </Stack>
                    </Box>
                  )}

                  <Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack direction="column" alignItems="center">
                        <Fab aria-label="phone" color="error" className={cx('img-m', 'h-w-45')} component={Link} to="/">
                          <LtePlusMobiledataIcon />
                        </Fab>
                        <b className={cx('font-11')}>Di Động</b>
                      </Stack>
                    </Box>
                  </Box>

                  {/* <StyledFab color="error" aria-label="add" component={Link} to="/" sx={{ ml: 4 }}>
                  <StorefrontOutlinedIcon />
                </StyledFab>
                <Box sx={{ flexGrow: 1 }} /> */}
                  <Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack direction="column" alignItems="center">
                        <Fab
                          aria-label="phone"
                          color="success"
                          className={cx('img-m', 'h-w-45')}
                          component={Link}
                          to="/internet"
                        >
                          <RouterIcon />
                        </Fab>
                        <b className={cx('font-11')}>Wifi, Truyền Hình</b>
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>
      </Paper>
    </Box>
  );
}

export default Footer;
