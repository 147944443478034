import PropTypes from 'prop-types';
import SimCardIcon from '@mui/icons-material/SimCard';
import DoneIcon from '@mui/icons-material/Done';

import classNames from 'classnames/bind';
import styles from './FrameInfoSim.module.scss';
import images from '../../../assets/images';
import ButtonUI from '../ButtonUI/ButtonUI';
import { Button, Stack } from '@mui/material';
const cx = classNames.bind(styles);

function FrameInfoSim({ bgrFrame = 'df', title = '', sms = '', fontSize = 'small', arrDetailSim = [], onClick1 }) {
  let CompIcon = DoneIcon;
  const props = { onClick1 };
  return (
    <div className={cx(`header-title-${bgrFrame}`)} {...props}>
      {/* <div className={cx(`header-title-item-${bgrFrame}`)}>
        <b> {title}</b>
      </div> */}
      <div className={cx('container_red')}>
        <img src={images.img_similar_pack} alt="" width="100%" />
        <h5 className={cx('similar-pack__name')}>{title}</h5>
      </div>
      <div className={cx('detail-item-sim', `font-size-${fontSize}`)}>
        {arrDetailSim &&
          arrDetailSim.map((sim, index) => (
            <div key={index} className={cx('title-item')}>
              {/* {sim && <CompIcon fontSize="small" className={cx(`svg_icons_ticker_${bgrFrame}`)} />} */}
              {index === 0 && <img src={images.icon_dola} alt="" width="30px" />}
              {index === 1 && <img src={images.icon_data} alt="" width="30px" />}
              {index === 0 && <span className={cx('font-18')} dangerouslySetInnerHTML={{ __html: sim }} />}
              {index === 1 && <span className={cx('cat-chuoi')} dangerouslySetInnerHTML={{ __html: sim }} />}
            </div>
          ))}
      </div>
      <div className={cx('btn-item-sim')}>
        <Stack direction={'row'} spacing={1}>
          <ButtonUI
            bgrColorBtn="#fd3a51"
            colorText="#fff"
            borderBtn="#fd3a51"
            borderRadius={10}
            href={`sms:9123?&body=${sms}`}
          >
            ĐĂNG KÝ
          </ButtonUI>
          <ButtonUI bgrColorBtn="#fff" colorText="#ff006f" borderBtn="#fff" borderRadius={10} onClick={onClick1}>
            <b>Chi tiết</b>
          </ButtonUI>
        </Stack>
      </div>
    </div>
  );
}

FrameInfoSim.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  arrDetailSim: PropTypes.array,
  onClick1: PropTypes.func,
};

export default FrameInfoSim;
