import { useEffect, useState } from 'react';
import { Alert, Button, Container, Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { listUserIDAll, updateUserID } from '../../../services/apiUsers';
import { checkTokenDashboad } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

function DashboardAdmin() {
  const hostname = window.location.hostname;
  const [resultAdmin, setResultAdmin] = useState({});
  const [message, setMessage] = useState('');
  const [resultAdminID, setResultAdminID] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const navigate = useNavigate();

  const fetchApiAdmin = async () => {
    const resultAPISIM = await listUserIDAll(hostname);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultAdmin(resultAPISIM[0]);
      setResultAdminID({
        ADDRESS: resultAPISIM[0]['ADDRESS'],
        GMAIL_NOTIFI: resultAPISIM[0]['GMAIL_NOTIFI'],
        HOTTLINE_SIM: resultAPISIM[0]['HOTTLINE_SIM'],
        HOTTLINE_SIM_CALL: resultAPISIM[0]['HOTTLINE_SIM_CALL'],
        HOTTLINE_ZALO: resultAPISIM[0]['HOTTLINE_ZALO'],
        TITLE_HEADER: resultAPISIM[0]['TITLE_HEADER'],
        TITLE_HOME: resultAPISIM[0]['TITLE_HOME'],
        layoutSim: resultAPISIM[0]['layoutSim'],
        link_gg: resultAPISIM[0]['link_gg'],
      });
    }
  };

  useEffect(() => {
    fetchApiAdmin();
    if (checkTokenDashboad('1') || checkTokenDashboad('2') || checkTokenDashboad('3') || !checkTokenDashboad('5')) {
      console.log('OK');
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  const handleChangValueAdmin = (jsonVL) => {
    setResultAdminID({ ...resultAdminID, ...jsonVL });
  };

  const handelUpdateAdmin = async () => {
    setLoading(true);
    const body = {
      idUser: resultAdmin.id,
      data: resultAdminID,
    };
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'Ok') {
      setMessage('Thành Công');
      setLoading(false);
    } else {
      setMessage('Thất Bại');
      setLoading(false);
    }
  };
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack direction={'row'} sx={{ mb: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              color={loadingEdit ? 'success' : 'warning'}
              onClick={() => setLoadingEdit(!loadingEdit)}
            >
              {loadingEdit ? 'Chỉnh Sửa' : 'Đóng Lại'}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          {message && (
            <Alert severity={message === 'Thành Công' ? 'success' : 'error'}>
              {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
            </Alert>
          )}
        </Grid>
        <Grid item md={6} xs={12} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            {/* <TextField
              label="ID URL Trang Tính"
              size="small"
              placeholder="nhập id trang tính"
              value={resultAdminID?.link_gg || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ link_gg: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            /> */}

            {/* <TextField
              label="Email thông báo sim"
              size="small"
              placeholder="nhập mail thông báo đặt sim"
              value={resultAdminID?.GMAIL_NOTIFI || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ GMAIL_NOTIFI: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            /> */}
            <TextField
              label="Ghi chú đầu trang"
              size="small"
              placeholder="nhập ghi chú - có thể ghi địa chỉ"
              value={resultAdminID?.ADDRESS || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ ADDRESS: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            <TextField
              label="Hotline hiển thị 1 or nhiều"
              size="small"
              placeholder="nhập hotline hiển thị"
              value={resultAdminID?.HOTTLINE_SIM || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ HOTTLINE_SIM: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            <TextField
              label="Hotline liên hệ"
              size="small"
              placeholder="nhập hotline liên hệ không dấu ."
              value={resultAdminID?.HOTTLINE_SIM_CALL || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ HOTTLINE_SIM_CALL: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            <TextField
              label="Zalo liên hệ"
              size="small"
              placeholder="nhập zalo liên hệ không dấu ."
              value={resultAdminID?.HOTTLINE_ZALO || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ HOTTLINE_ZALO: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            <TextField
              label="Chữ trên header"
              size="small"
              placeholder="nhập chữ hiển thị"
              value={resultAdminID?.TITLE_HEADER || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ TITLE_HEADER: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            <TextField
              label="Title trang sim"
              size="small"
              placeholder="nhập title trang sim"
              value={resultAdminID?.TITLE_HOME || ''}
              //   defaultValue={resultAdmin?.NOTIFI}
              onChange={(e) => {
                handleChangValueAdmin({ TITLE_HOME: e.target.value });
              }}
              focused
              required
              disabled={loadingEdit}
            />
            {/* <Select
              value={resultAdminID?.layoutSim || ''}
              label="Chọn giao diện"
              onChange={(e) => {
                handleChangValueAdmin({ layoutSim: e.target.value });
              }}
              disabled={loadingEdit}
            >
              <MenuItem value={1}>Giao diện thu gọn</MenuItem>
              <MenuItem value={2}>Giao diện chi tiết</MenuItem>
            </Select> */}
            <Button variant="contained" color="error" onClick={handelUpdateAdmin} disabled={loading}>
              Cập Nhật
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DashboardAdmin;
