import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import images from '../../../assets/images';
import styles from './Header.module.scss';
import auth from '../../../config/auth';
import { Box, Hidden, Stack } from '@mui/material';
import * as userIDService from '../../../services/apiUsers';
const cx = classNames.bind(styles);

function Header() {
  const navigate = useNavigate();
  const contextApiUser = useContext(ThemeContext);
  let authDomain = contextApiUser.dataUser;

  const handleNav = () => {
    navigate('/', { replace: true });
    window.location.reload();
  };
  const redirectToExternalLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {authDomain['LINKNOTIFI'] && (
        <div className={cx('wrapper-notifi')}>
          <span className={cx('ping')}></span>
          <b
            className={cx('notifi')}
            onClick={() => {
              redirectToExternalLink(authDomain['LINKNOTIFI']);
            }}
          >
            {authDomain['NOTIFI']}
          </b>
        </div>
      )}
      {authDomain && (
        <header className={cx('wrapper')}>
          <div className={cx('inner')}>
            <Hidden only={['sm', 'xs']}>
              <Grid container>
                <Grid item xs={4} className={cx('logo-left-md')} onClick={() => handleNav()}>
                  <img src={images[authDomain['logo']]} alt="Khosim" className={cx('logo')} />
                  <label className={cx('color-logo-title')}>
                    <b>{authDomain['TITLE_HEADER']}</b>
                  </label>
                </Grid>
                <Grid item xs={4} className={cx('logo-right-md')}>
                  {authDomain['ADDRESS'] && (
                    <Grid item xs={12} className={cx('logo-center')}>
                      <label className={cx('color-hotline')}>
                        <b className={cx('color-red')}>{authDomain['ADDRESS']}</b>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={4} className={cx('logo-right-md')}>
                  {authDomain['HOTTLINE_SIM'] && (
                    <Grid item xs={12} className={cx('logo-center')}>
                      <label className={cx('color-hotline')}>
                        <b className={cx('color-black', 'dis-flex')}>
                          <PhoneInTalkRoundedIcon sx={{ mr: 1 }} color="error" /> {authDomain['HOTTLINE_SIM']}
                        </b>
                      </label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Grid container>
                <Grid item xs={12} className={cx('logo-center')} onClick={() => handleNav()}>
                  <img src={images[authDomain['logo']]} alt="Khosim8" className={cx('logo')} />
                  <label className={cx('color-logo-title')}>
                    <b>{authDomain['TITLE_HEADER']}</b>
                  </label>
                </Grid>
                {authDomain['HOTTLINE_SIM'] && (
                  <Grid item xs={12} className={cx('logo-center')}>
                    <label className={cx('color-hotline-xs')}>
                      <b className={cx('color-black', 'dis-flex')}>
                        <PhoneInTalkRoundedIcon sx={{ mr: 1 }} color="error" /> {authDomain['HOTTLINE_SIM']}
                      </b>
                    </label>
                  </Grid>
                )}
                {authDomain['ADDRESS'] && (
                  <Grid item xs={12} className={cx('logo-center')}>
                    <label className={cx('color-hotline-xs')}>
                      <b className={cx('color-red')}>{authDomain['ADDRESS']}</b>
                    </label>
                  </Grid>
                )}

                {/* <Grid item md={11.5} xs={10.5}>
            <Search />
          </Grid>
          <Grid item md={0.5} xs={1.5}>
            <IconButton aria-label="light" size="large" sx={{ pl: '25px', pb: '7px', pt: '4px' }} onClick={handleBoLoc}>
              <FilterAltRoundedIcon fontSize="inherit" style={{ color: 'rgb(255 223 227)' }} />
            </IconButton>
          </Grid> */}
              </Grid>
            </Hidden>
          </div>
        </header>
      )}
    </>
  );
}
export default Header;
