import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CabinIcon from '@mui/icons-material/Cabin';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SimCardIcon from '@mui/icons-material/SimCard';

import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { AppBar, Grid, Toolbar } from '@mui/material';
import classNames from 'classnames/bind';
import styles from '../Footer/Footer.module.scss';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { checkTokenDashboad, getLocalStorage, removeLocalStorage } from '../../../utils/helper';
import { ButtonUI } from '../../../component/CustomerMUI';
const cx = classNames.bind(styles);

function Footer() {
  const navigate = useNavigate();
  const [authDomain, setAuthDomain] = useState({});
  const getToken = getLocalStorage('token');

  useEffect(() => {
    if (getToken) {
      if (checkTokenDashboad('1') || checkTokenDashboad('2') || checkTokenDashboad('3') || checkTokenDashboad('5')) {
        setAuthDomain(getToken);
      } else {
        navigate('/login', { replace: true });
      }
    }
  }, []);
  return (
    <Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }} elevation={3}>
        <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, backgroundColor: '##f9f9f9' }}>
          <Grid container justifyContent="center" sx={{ pt: '5px' }}>
            <Grid item md={6} xs={12}>
              <Toolbar sx={{ justifyContent: 'center', ml: 2 }}>
                <Stack direction="row" spacing={3} alignItems="stretch">
                  <Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack direction="column" alignItems="center">
                        <Fab
                          aria-label="phone"
                          color="success"
                          className={cx('img-m', 'h-w-45')}
                          component={Link}
                          to="/dashboard"
                        >
                          <CabinIcon />
                        </Fab>
                        <b>Dashboard</b>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack direction="column" alignItems="center">
                        <Fab
                          aria-label="phone"
                          color="error"
                          className={cx('img-m', 'h-w-45')}
                          component={Link}
                          to="/dashboard/simdata"
                        >
                          <SimCardIcon />
                        </Fab>
                        <b>QL Gói Cước</b>
                      </Stack>
                    </Box>
                  </Box>
                  {authDomain?.permission !== '5' && (
                    <Box>
                      <Box sx={{ mt: 1 }}>
                        <Stack direction="column" alignItems="center">
                          <Fab
                            aria-label="phone"
                            color="primary"
                            className={cx('img-m', 'h-w-45')}
                            component={Link}
                            to="/dashboard/admin"
                          >
                            <PermContactCalendarIcon />
                          </Fab>
                          <b>Admin</b>
                        </Stack>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Toolbar>
            </Grid>
            {getLocalStorage('token') && (
              <ButtonUI
                borderBtn="#d1fae5"
                color="error"
                style={{
                  position: 'fixed',
                  bottom: 70,
                  left: 10,
                  display: 'block',
                  zIndex: 10,
                }}
                onClick={() => {
                  removeLocalStorage('token');
                  navigate('/login', { replace: true });
                }}
              >
                Đăng xuất
              </ButtonUI>
            )}
          </Grid>
        </AppBar>
      </Paper>
    </Box>
  );
}

export default Footer;
