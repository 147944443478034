import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import classNames from 'classnames/bind';
import styles from './simdata.module.scss';
import { checkTokenDashboad, getLocalStorage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import {
  addSimData,
  deleSimDataID,
  getSimDataID,
  listIDCatePater,
  listSimDataIDAll,
  updateSimDataID,
} from '../../../services/apiSimData';
import { API_URL_SIMSO } from '../../../config/config';
const cx = classNames.bind(styles);

function SimData() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      borderBottom: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  const datenow = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

  const [resultCateChild, setResultCateChild] = useState([]);
  const [resultSimData, setResultSimData] = useState([]);
  const [resultSimDataID, setResultSimDataID] = useState({
    id_cate: 1,
    id_cate_child: '',
    title: '',
    price: '',
    dategoi: '',
    tocdogoi: '',
    content: '',
    phone_sms: '',
    phone_call: '',
  });
  const navigate = useNavigate();

  const fetchApiSimData = async () => {
    const resultAPISIM = await listSimDataIDAll(getLocalStorage('token')['id']);
    if (resultAPISIM['data'] && resultAPISIM['data'].length > 0) {
      setResultSimData(resultAPISIM['data']);
    }
  };
  const fetchApiCateChild = async (id) => {
    const resultAPISIM = await listIDCatePater(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultCateChild(resultAPISIM);
    }
  };

  const handleGetSimDataID = async (id) => {
    const resultAPISIM = await getSimDataID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultSimDataID(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    fetchApiSimData();
    fetchApiCateChild(1);
  }, []);

  const handleChangValueSimData = (jsonVL) => {
    setResultSimDataID({ ...resultSimDataID, ...jsonVL, update_date: datenow });
  };

  const handelUpdateSimData = async () => {
    const resultAPISIM = await updateSimDataID(resultSimDataID);
    if (resultAPISIM['data'].length > 0) {
      setResultSimDataID({
        id_cate: 1,
        id_cate_child: '',
        title: '',
        price: '',
        dategoi: '',
        tocdogoi: '',
        content: '',
        phone_sms: '',
        phone_call: '',
      });
      setResultSimData(resultAPISIM['data']);
    }
  };
  const handelAddSimData = async () => {
    const data = {
      ...resultSimDataID,
      id_user: getLocalStorage('token')['id'],
      status: '1',
      create_date: datenow,
    };
    const resultAPISIM = await addSimData(data);
    if (resultAPISIM['data'].length > 0) {
      setResultSimDataID({
        id_cate: 1,
        id_cate_child: '',
        title: '',
        price: '',
        dategoi: '',
        tocdogoi: '',
        content: '',
        phone_sms: '',
        phone_call: '',
      });
      setResultSimData(resultAPISIM['data']);
    }
  };

  const handleChangValueAdmin = (jsonVL) => {
    setResultSimDataID({ ...resultSimDataID, ...jsonVL });
  };

  const deleIDDataSim = async (data) => {
    const resultAPISIM = await deleSimDataID(data);
    setResultSimData(resultAPISIM['data']);
  };
  return (
    <Container sx={{ pb: '75px' }}>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <Stack direction={'column'} spacing={2}>
            <FormControl>
              <InputLabel>Thể loại </InputLabel>
              <Select
                value={resultSimDataID['id_cate']}
                label="Thể Loại"
                onChange={(e) => {
                  handleChangValueAdmin({ id_cate: e.target.value });
                  fetchApiCateChild(e.target.value);
                }}
              >
                <MenuItem value={'1'}>Di động</MenuItem>
                <MenuItem value={'2'}>Cố Định</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Thể loại con</InputLabel>
              <Select
                value={resultSimDataID['id_cate_child']}
                label="Thể Loại Con"
                onChange={(e) => {
                  handleChangValueAdmin({ id_cate_child: e.target.value });
                }}
              >
                {resultCateChild.map((categc, i) => (
                  <MenuItem value={categc.id} key={i}>
                    {categc.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Tên Gói"
              size="small"
              placeholder="vd: 1N,3FB30,ST5KM"
              value={resultSimDataID?.title}
              onChange={(e) => {
                handleChangValueSimData({ title: e.target.value });
              }}
              focused
              required
            />
            <TextField
              label="Giá Gói"
              size="small"
              placeholder="vd: 5000 (viết chuẩn theo mẫu)"
              value={resultSimDataID?.price}
              onChange={(e) => {
                handleChangValueSimData({ price: e.target.value });
              }}
              focused
              required
            />
            <TextField
              label="Thời Gian Gói"
              size="small"
              placeholder="vd: 90 ngày"
              value={resultSimDataID?.dategoi}
              onChange={(e) => {
                handleChangValueSimData({ dategoi: e.target.value });
              }}
              focused
              required
            />
            {resultSimDataID['id_cate'] === '2' && (
              <TextField
                label="Tốc Độ Gói"
                size="small"
                placeholder="vd: 100 Mbps"
                value={resultSimDataID?.tocdogoi}
                onChange={(e) => {
                  handleChangValueSimData({ tocdogoi: e.target.value });
                }}
                focused
                required
              />
            )}
            <TextField
              label="Nội Dung Gói"
              size="small"
              placeholder="nhập nội dung gói"
              value={resultSimDataID?.content}
              onChange={(e) => {
                handleChangValueSimData({ content: e.target.value });
              }}
              focused
              required
              multiline
              rows={4}
            />
            {resultSimDataID['id_cate'] === '1' && (
              <TextField
                label="Cú pháp sms"
                size="small"
                placeholder="nhập cú pháp sms"
                value={resultSimDataID?.phone_sms}
                onChange={(e) => {
                  handleChangValueSimData({ phone_sms: e.target.value });
                }}
                focused
                required
              />
            )}
            {resultSimDataID['id_cate'] === '2' && (
              <TextField
                label="Số Điện Thoại"
                size="small"
                placeholder="nhập số điện thoại khách gọi"
                value={resultSimDataID?.phone_call}
                onChange={(e) => {
                  handleChangValueSimData({ phone_call: e.target.value });
                }}
                focused
                required
              />
            )}
            <Button
              variant="contained"
              color="error"
              onClick={resultSimDataID?.id ? handelUpdateSimData : handelAddSimData}
            >
              {resultSimDataID?.id ? 'Cập Nhật' : 'Lưu Lại'}
            </Button>
          </Stack>
        </Grid>
        <Grid item md={9} xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Thể Loại</StyledTableCell>
                  <StyledTableCell align="center">Thể Loại Con</StyledTableCell>
                  <StyledTableCell align="center">Tên Gói</StyledTableCell>
                  <StyledTableCell align="center">Giá</StyledTableCell>
                  <StyledTableCell align="center">Tốc Độ Gói</StyledTableCell>
                  <StyledTableCell align="center">Thời Gian Gói</StyledTableCell>
                  <StyledTableCell align="center">Nội Dung</StyledTableCell>
                  <StyledTableCell align="center">SMS</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">Tác Vụ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultSimData?.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="center">{row.id_cate === 1 ? 'Di Động' : 'Cố Định'}</StyledTableCell>
                    <StyledTableCell align="center">{row.catechild}</StyledTableCell>
                    <StyledTableCell align="center">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">{row.price}</StyledTableCell>
                    <StyledTableCell align="center">{row.tocdogoi}</StyledTableCell>
                    <StyledTableCell align="center">{row.dategoi}</StyledTableCell>
                    <StyledTableCell align="center" className={cx('internet-tele__txt')}>
                      {row.content}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.phone_sms}</StyledTableCell>
                    <StyledTableCell align="center">{row.phone_call}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => handleGetSimDataID(row.id)}
                        sx={{ color: resultSimDataID.id === row.id ? `red` : `` }}
                      >
                        Sửa
                      </Button>
                      <Button onClick={() => deleIDDataSim({ id: row.id, id_user: row.id_user })}>Xóa</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SimData;
