const images = {
  logo: require('./logo8.png'),
  logoxs: require('./logo8.png'),
  logologin: require('./logologin.png'),
  logokhoso: require('./logonew.png'),
  logosuccess: require('./logosuccess.png'),
  iconZalo: require('./icon-zalo.png'),
  logo_mb: require('./logo-mobifone.svg').default,
  logo_vt: require('./logo-viettel.svg').default,
  logo_vn: require('./logo-vinaphone.svg').default,
  logo_vnmb: require('./logo-vnmb.svg').default,
  logo_itel: require('./itelecom_logo.png'),
  logo_wintel: require('./wintel_logo.png'),
  mobifone_small: require('./mobifone_small.svg').default,
  viettel_small: require('./viettel_small.svg').default,
  vinaphone_small: require('./vinaphone_small.svg').default,
  vnmb_small: require('./vnmb_small.svg').default,
  itel_small: require('./itel_small.svg').default,
  reddi_small: require('./reddi_small.svg').default,
  img_similar_pack: require('./img-similar-pack.svg').default,
  icon_dola: require('./icon-dola.svg').default,
  icon_data: require('./icon-data.svg').default,
  ic_it_2: require('./ic-it-2.svg').default,
  img_internet_3: require('./img-internet-3.svg').default,
  img_it_thumbnail: require('./img-it-thumbnail.svg').default,
  phoi_viettel: require('./phoi-viettel.png'),
  phoi_vina: require('./phoi-vina.png'),
  phoi_mobi: require('./phoi-mobi.png'),
  phoi_vnmb: require('./phoi-vnmb.png'),
  phoi_itel: require('./phoi-itel.png'),
  phoi_default: require('./phoi_default.png'),
  phoi_vina_1: require('./vinaphone_phoi.png'),
  messenger: require('./messenger.png'),
  bannerqcvina: require('./salevina.png'),
  flashsale: require('./flash-sale.png'),
  lock: require('./lock.png'),
  phoisimdep: require('./phoisimdep.png'),
};

export default images;
