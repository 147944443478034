import * as requestAPI from '../utils/requestAPI';
import * as reqAuth from '../utils/requestAuthServer';

//API THÊM USER
export const addUsers = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addusers`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listUserID = async (domain) => {
  try {
    const res = await requestAPI.getSim(`/listUserIDShow?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listUserIDAll = async (domain) => {
  try {
    const res = await reqAuth.getSim(`/listUserIDAll?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserID = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updateUserIDShow`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// check login
export const checkLoginUsers = async (data) => {
  try {
    const res = await requestAPI.postSim(`/loginUser`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
// refresh token
export const refreshToken = async (data) => {
  try {
    const res = await reqAuth.postSim(`/refresh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
