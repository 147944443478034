const routes = {
  home: '/',
  homeInternet: '/:idUrl',
  dashboardLogin: '/login',
  dashboard: '/dashboard',
  dashboardNotifi: '/dashboard/notifisim',
  dashboardAdmin: '/dashboard/admin',
  dashboardDataSim: '/dashboard/simdata',
  dashboardCateSim: '/dashboard/categoicuoc',
};
export default routes;
