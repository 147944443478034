import axios from 'axios';
import { API_URL_SIMSO } from '../config/config';

const requestAPISIM = axios.create({
  baseURL: API_URL_SIMSO,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSim = async (path, options = {}) => {
  const response = await requestAPISIM.get(path, options);
  return response.data;
};

export const postSim = async (path, options = {}) => {
  const response = await requestAPISIM.post(path, options);
  return response.data;
};
export const postUpload = async (path, options = {}) => {
  const response = await requestAPISIM.post(path, options, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
