import * as requestAPI from '../utils/requestAPI';
const hostname = window.location.hostname;
//API THÊM USER
export const addSimData = async (data) => {
  try {
    const res = await requestAPI.postSim(`/addsimdata`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listSimDataIDAll = async (user) => {
  try {
    const res = await requestAPI.getSim(`/listsimdataall?u=${user}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listSimDataCate = async (id_cate) => {
  try {
    const res = await requestAPI.getSim(`/listsimdatacate?cate=${id_cate}&domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listSimDataCateChild = async (id_cate, id_cate_child) => {
  try {
    const res = await requestAPI.getSim(
      `/listsimdatacatechild?cate=${id_cate}&cate_child=${id_cate_child}&domain=${hostname}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIDCatePater = async (id_cate) => {
  try {
    const res = await requestAPI.getSim(`/getcatepatergcid?id_cate=${id_cate}&domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getSimDataAll = async () => {
  try {
    const res = await requestAPI.getSim(`/getsimdataall?domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getSimDataID = async (id) => {
  try {
    const res = await requestAPI.getSim(`/getdatasimsoid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateSimDataID = async (data) => {
  try {
    const res = await requestAPI.postSim(`/updatesimdata`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleSimDataID = async (data) => {
  try {
    const res = await requestAPI.postSim(`/deleiddatasim`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageBanner = async (formData) => {
  try {
    const res = await requestAPI.postUpload(`/uploadimagebn`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
