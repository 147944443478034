import { useEffect, useState } from 'react';
import { Card, CardContent, Container, Grid, Paper, Stack } from '@mui/material';
import SimCardIcon from '@mui/icons-material/SimCard';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

import classNames from 'classnames/bind';
// import styles from './dashboard.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { checkTokenDashboad, getLocalStorage } from '../../utils/helper';
// const cx = classNames.bind(styles);

function Dashboard() {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const [authDomain, setAuthDomain] = useState({});

  useEffect(() => {
    const getToken = getLocalStorage('token');
    if (checkTokenDashboad('1') || checkTokenDashboad('2') || checkTokenDashboad('3') || checkTokenDashboad('5')) {
      setAuthDomain(getToken);
    } else {
      navigate('/login', { replace: true });
    }
    document.title = 'Tổng quan quản trị website ' + hostname;
  }, []);
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6} component={Link} to="/dashboard/admin">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Quản Lý Thông Tin</h3>
                  <PermContactCalendarIcon color="action" />
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Thêm sửa xóa thông tin...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} component={Link} to="/dashboard/notifisim">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Quản Lý Thông Báo Sim</h3>
                  <NotificationsActiveIcon color="action" />
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Cập nhật hiển thị thông báo trên đầu trang sim</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} component={Link} to="/dashboard/simdata">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Quản Lý Sim Data</h3>
                  <SimCardIcon color="action" />
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Cập nhật hiển thị sim data, internet...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} component={Link} to="/dashboard/categoicuoc">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Quản Lý Thể Loại</h3>
                  <SimCardIcon color="action" />
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Cập nhật hiển thị thể loại...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
