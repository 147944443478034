import PropTypes from 'prop-types';
import SimCardIcon from '@mui/icons-material/SimCard';
import DoneIcon from '@mui/icons-material/Done';

import classNames from 'classnames/bind';
import styles from './FrameInfoSim.module.scss';
import images from '../../../assets/images';
import ButtonUI from '../ButtonUI/ButtonUI';
import { Button, Stack } from '@mui/material';
const cx = classNames.bind(styles);

function FrameInfoSim1({ bgrFrame = 'df', title = '', phone = '', fontSize = 'small', arrDetailSim = [], onClick1 }) {
  let CompIcon = DoneIcon;
  const props = { onClick1 };
  console.log(arrDetailSim[2]);
  return (
    <div className={cx(`header-title-${bgrFrame}`)} {...props}>
      {/* <div className={cx(`header-title-item-${bgrFrame}`)}>
        <b> {title}</b>
      </div> */}
      <div className={cx('container_red')}>
        <img src={images.img_internet_3} alt="" width="100%" className={cx('border-radius-top-1')} />
        <h5 className={cx('similar-pack__name_1')}>{title}</h5>
      </div>
      <div className={cx('detail-item-sim_1')}>
        <img src={images.img_it_thumbnail} alt="" width="30px" />
        <h3 dangerouslySetInnerHTML={{ __html: arrDetailSim[0] }} />
      </div>
      <div className={cx('detail-item-sim', `font-size-${fontSize}`)}>
        {arrDetailSim && (
          <div className={cx('title-item-inter')}>
            <img src={images.icon_dola} className={cx('mr-5')} alt="" width="30px" />
            <h3 className={cx('cat-chuoi', 'red', 'font-18')} dangerouslySetInnerHTML={{ __html: arrDetailSim[2] }} />
          </div>
        )}
      </div>
      <div className={cx('detail-item-sim', `font-size-${fontSize}`, 'mt-18')}>
        {arrDetailSim && (
          <div className={cx('title-item_1')}>
            {/* {sim && <CompIcon fontSize="small" className={cx(`svg_icons_ticker_${bgrFrame}`)} />} */}

            <img src={images.ic_it_2} alt="" width="30px" />
            <span className={cx('internet-tele__txt')} dangerouslySetInnerHTML={{ __html: arrDetailSim[1] }} />
          </div>
        )}
      </div>
      <div className={cx('btn-item-sim')}>
        <Stack direction={'row'} spacing={1}>
          <ButtonUI bgrColorBtn="#fd3a51" colorText="#fff" borderBtn="#fd3a51" borderRadius={10} href={`tel:${phone}`}>
            GỌI NGAY
          </ButtonUI>
          <ButtonUI bgrColorBtn="#fff" colorText="#ff006f" borderBtn="#fff" borderRadius={10} onClick={onClick1}>
            <b>Chi tiết</b>
          </ButtonUI>
        </Stack>
      </div>
    </div>
  );
}

FrameInfoSim1.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  arrDetailSim: PropTypes.array,
  onClick1: PropTypes.func,
};

export default FrameInfoSim1;
